<template>
  <div class="w-full h-screen container mx-auto">
    <div class="w-full h-1/6 flex justify-center">
      <div class="w-1/3 flex flex-row justify-start items-center">
        <div class="m-4">
          <span class="text-4xl font-bold text-blue-900 uppercase font-roboto mr-0.5">монитор</span>
          <span class="text-4xl font-bold text-black uppercase font-roboto">цеха</span>
        </div>
      </div>
      <div class="w-1/3">
      </div>
      <div class="w-1/3 flex flex-row justify-end items-center">
        <span @click="exit()" class="text-xl font-bold text-blue-900 font-roboto mr-0.5 cursor-pointer">Выйти</span>
      </div>
    </div>
    <div class="w-full h-5/6 flex flex-col justify-start items-center">
        <div class="w-full rounded-lg bg-blue-900 px-9 py-4 my-4">
          <span class="text-white font-roboto font-medium text-lg">Выбор экрана</span>
        </div>
        <div class="w-full flex flex-row justify-between items-center">
          <div class="w-1/3">
            <div class="ml-5 flex flex-row items-center">
              <checkbox @checkMethod="changeScreenMode" screenName="withScreen"/>
              <span>Монитор цеха с фото</span>
            </div>
            <img class="w-full h-full" :src="require('@/assets/images/screen-with-photo.svg')" alt="screen">
          </div>
          <div class="w-1/3">
            <div class="ml-5 flex flex-row items-center">
              <checkbox @checkMethod="changeScreenMode" screenName="withoutScreen"/>
              <span>Монитор цеха без фото</span>
            </div>
            <img class="w-full h-full" :src="require('@/assets/images/screen-without-photo.svg')" alt="screen">
          </div>
          <div class="w-1/3">
            <div class="ml-5 flex flex-row items-center">
              <checkbox @checkMethod="changeScreenMode" screenName="statistic"/>
              <span>Монитор цеха со статистикой</span>
            </div>
            <img class="w-full h-full" :src="require('@/assets/images/screen-with-statistic.svg')" alt="screen">
          </div>
        </div>
        <div class="w-full rounded-lg bg-blue-900 px-9 py-4 mb-4 mt-8">
          <span class="text-white font-roboto font-medium text-lg">Частота обновления монитора</span>
        </div>
        <div class="w-full flex flex-row justify-start items-center my-4">
          <checkbox @checkMethod="setFrequency" :frequencyVal="600000"/>
          <span class="mr-8 font-roboto font-normal text-gray-900">Раз в 10 минут</span>
          <checkbox @checkMethod="setFrequency" :frequencyVal="300000"/>
          <span class="mr-8 font-roboto font-normal text-gray-900">Раз в 5 минут</span>
          <checkbox @checkMethod="setFrequency" :frequencyVal="60000"/>
          <span class="mr-8 font-roboto font-normal text-gray-900">Раз в 1 минуту</span>
        </div>
        <button @click="$router.push('/main')" class="font-sans font-semibold text-xl text-white bg-blue-900 rounded-xl focus:outline-none px-5 py-3 mt-8">Продолжить</button>
    </div>
  </div>
</template>

<script>
  import globalParams from "@/mixins/globalParams.mixin.js";
  import Checkbox from "@/components/Checkbox.vue";
  export default {
    name: 'Params',
    mixins: [globalParams],
    components: {
      Checkbox
    }
  }
</script>
