<template>
  <div v-if="screenName!=undefined" @click="checkMethod" class="flex flex-col justify-center items-center w-5 h-5 rounded-full border border-blue-900 cursor-pointer mr-2">
    <div v-if="screenMode[screenName]" class="w-3.5 h-3.5 rounded-full bg-blue-900"></div>
  </div>
  <div v-if="frequencyVal!=undefined" @click="checkMethod" class="flex flex-col justify-center items-center w-5 h-5 rounded-full border border-blue-900 cursor-pointer mr-2">
    <div v-if="updateParametr === frequencyVal" class="w-3.5 h-3.5 rounded-full bg-blue-900"></div>
  </div>
</template>

<script>

import globalParams from "@/mixins/globalParams.mixin.js";

export default {
  name: "checkbox",
  mixins: [globalParams],
  props : {
    screenName : {
      type: String
    },
    frequencyVal : {
      type: Number
    }
  },
  methods : {
    checkMethod() {

      if(this.screenName!= undefined)
        this.$emit('checkMethod', this.screenName)

      if(this.frequencyVal != undefined)
        this.$emit('checkMethod',this.frequencyVal)

    }
  }
}


</script>
